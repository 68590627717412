class setWithdrawForm {
  constructor() {
    this.currencyType = null
    this.currencyTypeCode = null
    this.currencyKindCode = null
    this.currencyKindType = null
    this.currencyKindTypeCode = null
  }
}
class BRLForm {
  constructor() {
    this.realName = null
    this.email = null
    this.mobilePhone = null
    this.pixType = null
    this.pixKey = null
  }
}
class EURForm {
  constructor() {
    this.country = null
    this.name = null
    this.surname = null
    this.swift = null
    this.bankName = null
    this.iban = null
    this.mobilePhone = null
  }
}
class GBPForm {
  constructor() {
    this.country = null
    this.name = null
    this.surname = null
    this.bankCode = null
    this.bankName = null
    this.bankAccount = null
    this.mobilePhone = null
  }
}
class JPYForm {
  constructor() {
    this.country = null
    this.name = null
    this.surname = null
    this.zengin = null
    this.bankName = null
    this.bankAccount = null
    this.county = null
    this.mobilePhone = null
  }
}
class KRWForm {
  constructor() {
    this.country = null
    this.name = null
    this.surname = null
    this.swift = null
    this.ach = null
    this.bankName = null
    this.bankAccount = null
    this.state = null
    this.mobilePhone = null
  }
}
class MXNForm {
  constructor() {
    this.bankName = null
    this.account = null
    this.clabe = null
    this.mobilePhone = null
  }
}
class USDForm {
  constructor() {
    this.name = null
    this.surname = null
    this.bankName = null
    this.bankAccount = null
    this.mobilePhone = null
  }
}
class AUDForm {
  constructor() {
    this.country = null
    this.name = null
    this.surname = null
    this.bsb = null
    this.bankName = null
    this.bankAccount = null
    this.state = null
    this.mobilePhone = null
  }
}
export class PLNForm {
  constructor() {
    this.realName = null
    this.mobilePhone = null
    this.bankName = null
    this.bankAccount = null
  }
}


export {
  setWithdrawForm,
  BRLForm,
  EURForm,
  GBPForm,
  JPYForm,
  KRWForm,
  MXNForm,
  USDForm,
  AUDForm
}